<template lang="html">
  <div class="utilities">
    <div class="box" v-if="detail">
      <div v-if="isPay">
        <div class="content">
          <div class="headBox">
            <div class="list">
              <div class="left">
                缴费单位
              </div>
              <div class="right">
                <span class="gray">{{userInfo && userInfo.marketName}}</span>
              </div>
            </div>
            <div class="list">
              <div class="left">
                缴费摊位
              </div>
              <div class="right">
                <span class="gray">{{detail.noPay.merchantsStallNumber}}</span>
              </div>
            </div>
            <div class="list">
              <div class="left">
                户名
              </div>
              <div class="right">
                <span class="gray">{{detail && detail.noPay.contactName}}</span>
              </div>
            </div>
            <div class="list">
              <div class="left">
                上次缴费时间
              </div>
              <div class="right">
                <span class="gray">{{detail.noPay.lastTime}}</span>
              </div>
            </div>
          </div>
          <div class="listBox" v-for="(item, index) in detail.noPay.monthWater" :key="index">
            <div class="listTitle">
              <span>{{item.year_month}}</span>
            </div>
            <div class="listContent">
              <div class="list">
                <div class="left">
                  用水量
                </div>
                <div class="right">
                  {{item.electricity_number}}m³
                </div>
              </div>
              <div class="list">
                <div class="left">
                  水费单价
                </div>
                <div class="right">
                  <span class="selectText">{{item.month_price}}m³/元</span>
                </div>
              </div>
              <div class="list">
                <div class="left">
                  应缴金额
                </div>
                <div class="right">
                  {{item.month_money}}元
                </div>
              </div>
            </div>
          </div>
          <div class="aggregateAmount">
            应缴总金额：<span class="name">{{detail.noPay.money}}</span>
          </div>
        </div>
        <div class="desc" >
          <span>*</span>
          缴纳金额不可以修改，若有疑问联系工作人员
        </div>
        <div class="btn" >
          <van-button round type="primary" @click="affirm">确认缴纳</van-button>
        </div>
        <div class="query" @click="show = true">
          疑问？
        </div>
      </div>
      <div v-else>
        <div class="content">
          <div class="noPay">
            <img class="iconPay" src="../../assets/image/weijiaofei.png" alt="">
            <p class="desc">暂未查到欠费</p>
            <div class="time">
              <span class="name">最近缴费：{{detail.newPay && detail.newPay.pay_time}}</span>
              <span class="segmentation"></span>
              <span class="name">缴费金额：{{(detail.newPay &&  detail.newPay.key_pay_price) || 0}}元</span>
            </div>
          </div>
        </div>
        <div class="record">
          <div class="left">
            <img src="../../assets/image/jilu.png" alt="">
            <span>缴费记录</span>
          </div>
          <div class="right" @click="$router.push({path: '/contribute/record'})">
            立即进入
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" round position="bottom" :closeable="true">
      <div class="queryBox">
        <div class="title">
          联系市场人员
        </div>
        <div class="list" v-for="item in listData" :key="item.user_id">
          <div class="listTitle">
            市场管理员：
          </div>
          <div class="content">
            <div class="name">
              {{item.name}} {{item.mobile}}
            </div>
            <div class="phone" @click="$common.callPhone(item.mobile)">
              立即拨打
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'waterRates',
    data() {
      return {
        value: '',
        show: false,
        isPay: false,
        detail: null,
        userInfo: null,
        listData: [],
        BillIds: []
      };
    },
    created() {
      this.userInfo = JSON.parse(window.localStorage.getItem('propertyUserInfo')) || null;
      this.getDetail();
      this.getList();
    },
    methods: {
      getList() {
        this.$require.HttpPost('/h5/user/userList', { pageNum: 1, pageSize: 100 }).then(res => {
          if (res.data.list) {
            let arr = [];
            for (let i in res.data.list) {
              if (res.data.list[i].management.role_worker_id.indexOf('1100000000') != -1) {
                arr.push(res.data.list[i]);
              }
            }
            this.listData = arr;
          }
        });
      },
      getDetail() {
        this.$require.HttpPost('/h5/water_electricity_gas_rent/paymentWater').then(res => {
          if (res.data.status) {
            let arr = [];
            res.data.noPay.monthWater.forEach((item) => {
              arr.push(item.BillIds);
            });
            this.BillIds = arr.flat();
            this.isPay = true;
          } else {
            this.isPay = false;
          }
          this.detail = res.data;
        });
      },
      affirm() {
        this.$require.HttpPost('/h5/water_electricity_gas_rent/addWaterElectricityGasBillOffline', { bill_key: 10 }).then(res => {
          this.$require.HttpPost('/h5/water_electricity_gas_rent/changeMessageWaterElectricityGas', { bill_offline_no: res.data }).then(() => {
            this.$toast('缴费成功');
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .utilities {
    width: calc(100% - 48px);
    height: calc(100vh - 227px);
    background: url('../../assets/image/shuifeiBk.png') no-repeat;
    background-size: 100% 269px;
    padding: 227px 24px 0 24px;
    .box {
      padding-bottom: 50px;
      .content {
        .headBox {
          background: #FFFFFF;
          box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
          border-radius: 30px;
          padding: 0 22px;
        }
        .list {
          display: flex;
          justify-content: space-between;
          min-height: 106px;
          align-items: center;
          font-size: 28px;
          color: #000000;
          border-bottom: 2px solid #EFEFEF;
          &:last-child {
            border-bottom: 0;
          }
          .right {
            color: #333333;
            flex: 1;
            text-align: right;
            .name {
              word-wrap: break-word;
              word-break:break-all;
              width: 100%;
            }
            .selectText {
              color: #85C226;
            }
            .gray {
              color: #B9B9B9;
            }
          }
        }
        .listBox {
          .listTitle {
            height: 110px;
            line-height: 100px;
            padding-left: 20px;
            span {
              font-size: 28px;
              font-family: PingFang-SC-Bold;
              font-weight: bold;
              color: #000000;
              border-bottom: 2px solid #85C226;
              padding-bottom: 10px;
            }
          }
          .listContent {
            background: #FFFFFF;
            box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
            border-radius: 30px;
            padding: 0 22px;
          }
        }
        .aggregateAmount {
          text-align: center;
          margin-top: 60px;
          font-size: 32px;
          font-family: PingFang-SC-Bold;
          font-weight: bold;
          color: #000000;
          .name {
            font-size: 48px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: #F8C301;
          }
        }
        .noPay {
          padding-bottom: 60px;
          .iconPay {
            width: 412px;
            height: 412px;
            display: block;
            margin: 60px auto 40px auto;
          }
          .desc {
            font-size: 36px;
            color: #000000;
            text-align: center;
          }
          .time {
            text-align: center;
            color: #D2D2D2;
            font-size: 24px;
            margin-top: 30px;
            .segmentation {
              width: 2px;
              height: 24px;
              background: #D2D2D2;
              margin: 0 20px;
              display: inline-block;
            }
          }
        }
      }
      .desc {
        font-size: 24px;
        color: #D2D2D2;
        text-align: center;
        margin-top: 10px;
        span {
          color: #85C226;
        }
      }
      .btn {
        width: 468px;
        height: 96px;
        margin: 60px auto 30px auto;
      }
      .query {
        text-align: center;
        color: #529100;
        font-size: 24px;
      }
      .record {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
        .left {
          font-size: 28px;
          color: #000000;
          img {
            width: 23px;
            height: 28px;
            margin-right: 20px;
          }
        }
        .right {
          width: 160px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: #F3F9E9;
          border-radius: 30px;
          font-size: 28px;
          color: #85C226;
        }
      }
    }
    .queryBox {
      padding: 0 22px;
      .title {
        height: 112px;
        line-height: 112px;
        text-align: center;
        font-size: 32px;
        color: #000000;
      }
      .list {
        margin-bottom: 30px;
        .listTitle {
          font-size: 28px;
          color: #B2B2B2;
        }
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          .name {
            font-size: 28px;
            color: #000000;
            margin-right: 130px;
          }
          .phone {
            width: 160px;
            height: 60px;
            line-height: 60px;
            background: #F3F9E9;
            border-radius: 30px;
            font-size: 28px;
            color: #85C226;
            text-align: center;
          }
        }
      }
    }
  }
</style>
